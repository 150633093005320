.field-name-field-button-grid .field-collection-container {
  border-bottom: 0;
  margin-bottom: 0;
}

.field-name-field-button-grid-items {
  .field-items {
    display:  flex;
    flex-wrap: wrap;
    .field-item {
      flex: 1 0 33%;
      @media (max-width: 550px) {
        flex: 1 0 100%;
      }
      @media (min-width: 551px) and (max-width: 991px) {
        flex: 1 0 50%;
      }
      margin-bottom: 0;
      .field-collection-view {
        padding: 0;
        margin: 0;
        border-bottom: 0;
        position: relative;
      }
    }
  }
}

.button-grid-item-wrapper {
  position: relative;
  cursor: default;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.60) 100%);
  }
  .title {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 20px;
    color: #FFF;
    font: 2vw/1.1 @font-family-sans-serif;
    @media (max-width: 767px) {
      font-size: 20px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      font-size: 2.5vw;
    }
    z-index: 1;
    opacity: 1;
    transition: all 300ms;
  }
  &:hover {
    .inner {
      opacity: 1;
    }
    .title {
      opacity: 0;
    }
  }
  .inner {
    opacity: 0;
    transition: all 300ms;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(182, 213, 251, 0.8);
    padding: 10px 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .subtitle {
      font: 24px/1 @font-family-sans-serif;
      @media (max-width: 767px) {
        font-size: 18px;
      }
      margin-bottom: 14px;
    }
    .description {
      margin-bottom: 20px;
    }
    .description, .link {
      font: 16px/1 @font-family-sans-serif;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
    .link a {
      display: inline-block;
      padding: 6px 10px;
      border: 1px solid #000;
      border-radius: 5px;
      color: #000;
      transition: all 300ms;
      transform: scale(1,1);
      &:hover {
        text-decoration: none;
        background: #000;
        color: #FFF;
        transform: scale(1.1,1.1);
      }
    }
  }
}
