// Shared pane styles.
.pane-style(@bg-color) {
  background-color: @bg-color;
  padding: 15px 20px;
  font-family: @font-family-sans-serif;
  font-size: 15px;

  // Gives darker box shadow to darker styles, lighter shadow to lighter
  // styles, and no shadow to styles with transparency.
  & when (luma(@bg-color) <= 20%) and (alpha(@bg-color) = 1) {
    box-shadow: 0 0 10px 0 fade(black, 50%);
  }
  & when (luma(@bg-color) > 20%) and (alpha(@bg-color) = 1) {
    box-shadow: 0 0 10px 0 fade(black, 20%);
  }

  > .pane-title {
    font-family: @font-family-sans-serif;
    font-size: 22px;
    font-weight: bold;
    padding-bottom: 10px;
    margin: 0 0 10px;
    .fix-colors(@bg: @bg-color, @text: @text-color, @headings: false, @underline: false);

    // Light/dark border bottom.
    border-bottom: 2px solid fade(black, 50%);
    & when (luma(@bg-color) < 50%) and (alpha(@bg-color) > .5) {
      border-bottom: 2px solid fade(white, 50%);
    }
  }

  > .pane-content {
    .fix-colors(@bg: @bg-color, @text: @text-color);
  }
}

// Info.
.pane-style-style-info {
  .pane-style(@theme-pane-style-info-bg-color);
}

// Attention.
.pane-style-style-attention {
  .pane-style(@theme-pane-style-attention-bg-color);
}

// Subdued.
.pane-style-style-subdued {
  .pane-style(@theme-pane-style-subdued-bg-color);
}

// Alternate.
.pane-style-style-alternate {
  .pane-style(@theme-pane-style-alternate-bg-color);
}
