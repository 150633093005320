/**
 * Sets up basic styling for Superfish acccordion and horizontal menus. Colors,
 * font sizes, and other styling are too unique per site to be reasonably
 * included here.
 *
 * Example:
 *
 * .some-wrapper {
 *   .superfish-base();
 * }
 *
 * @submenu-top: the submenu top offset
 */
.superfish-base(@submenu-top: 100%) {
  .clearfix();

  // Don't underline links, and use fancy transitions.
  li, a, a:hover, a:focus {
    text-decoration: none;
    transition: all 200ms;
  }

  // Mobile menu toggle.
  .sf-accordion-toggle {
    a {
      display: block;
      text-align: center;
      text-transform: uppercase;
    }
  }

  // Mobile accordion menu.
  .sf-accordion {
    a {
      padding: 10px;
    }
    ul a {
      padding-left: 20px;
    }
    ul ul a {
      padding-left: 30px;
    }
    ul ul ul a {
      padding-left: 40px;
    }

    // Fixes unusual issue which is part of the Superfish-for-Drupal library but
    // not merged into the 1.x branch.
    // https://www.drupal.org/node/2559633
    // https://github.com/mehrpadin/Superfish-for-Drupal/pull/16
    &.sf-expanded {
      display: block !important;
    }
  }

  // Horizontal menu.
  .sf-horizontal {
    // Hide on mobile to prevent flash before JS can hide it.
    @media @mobile-only {
      display: none;
    }

    // Position horizontal submenus.
    > .sfHover > ul {
      top: @submenu-top;
      ul {
        margin-left: 1px;
      }
    }
  }

  // Mega menus.
  .sf-megamenu {
    .sf-megamenu-wrapper > ol {
      column-width: 225px;
    }
    li.sf-megamenu-column {
      break-inside: avoid;
      margin-bottom: 10px;
    }
    a.menuparent {
      font-weight: bold;
    }
  }
}

/**
 * Legacy setup mixin that has silly padding variable. New code should use
 * .superfish-base().
 *
 * Example:
 *
 * .some-wrapper {
 *   .superfish();
 * }
 *
 * @submenu-top: the submenu top offset
 * @submenu-left: the submenu left offset
 * @padding: some default padding
 */
.superfish(@submenu-top: 2em, @submenu-left: 0, @padding: 10px) {
  // Do base stuff.
  .superfish-base(@submenu-top);

  // Default padding.
  a {
    padding: @padding;
  }

  // Mobile accordion menu.
  .sf-accordion {
    ul a {
      padding-left: (@padding * 2);
    }
    ul ul a {
      padding-left: (@padding * 3);
    }
    ul ul ul a {
      padding-left: (@padding * 4);
    }
  }

  // Position horizontal submenus.
  .sf-horizontal > .sfHover > ul {
    left: @submenu-left;
  }
}

/**
 * Horizontally centers Superfish horizontal menus.
 *
 * Example:
 *
 * .some-wrapper {
 *   .superfish-centered();
 * }
 */
.superfish-centered() {
  .sf-horizontal {
    text-align: center;
    & > li {
      float: none;
      display: inline-block;
    }
    ul {
      text-align: left;
    }
  }
}

/**
 * Adds left/right separator borders to top-level horizontal Superfish links.
 *
 * Example:
 *
 * .some-wrapper {
 *   .superfish-horizontal-separators();
 * }
 *
 * @dark: the dark border color
 * @light: the light border color
 * @firstlast: true to show left border on first link and right border on last
 *   link, or false to hide these
 */
.superfish-horizontal-separators(
  @dark: fade(black, 50%),
  @light: fade(white, 20%),
  @firstlast: true
) {
  .sf-horizontal li.sf-depth-1 {
    border-left: 1px solid @dark;
    &.last when not (@firstlast = false) {
      border-right: 1px solid @light;
    }
    &.last > a when not (@firstlast = false) {
      border-right: 1px solid @dark;
    }
    > a {
      border-left: 1px solid @light;
    }
    &.first, &.first > a {
      & when (@firstlast = false) {
        border-left: none;
      }
    }
  }
}

/**
 * Adds vertical separator borders to Superfish links.
 *
 * Example:
 *
 * .some-wrapper {
 *   .superfish-vertical-separators();
 * }
 *
 * @color: the border color
 */
.superfish-vertical-separators(@color: fade(white, 20%)) {
  .sf-accordion a {
    border-top: 1px solid @color;
  }
  .sf-horizontal ul li:not(.last):not(.firstandlast) {
    border-bottom: 1px solid @color;
  }
}

/**
 * Adds icons to Superfish links.
 *
 * Example:
 *
 * .some-wrapper {
 *   .superfish-icons();
 * }
 *
 * @right: the character code for the right-pointing icon
 * @down: the character code for the down-pointing icon
 * @horizontal_top_down: if true, top-level horizontal icon will use down icon
 *   instead of right icon
 * @accordion-toggle: the character code for the accordion toggle icon
 * @position: the position of the top-level icon ('right' or 'bottom', or false
 *   to disable top-level icon)
 * @bottom: bottom position of arrow when @position is 'bottom'
 * @bottom-font-size: font size of arrow when @position is 'bottom'
 */
.superfish-icons(
  @right: '\e080',
  @down: '\e114',
  @horizontal_top_down: false,
  @accordion-toggle: '\e236',
  @position: 'right',
  @bottom: .625em,
  @bottom-font-size: .5em
) {
  // Mobile menu toggle.
  .sf-accordion-toggle {
    a:before {
      .glyphicon();
      content: @accordion-toggle;
      margin-right: 5px;
    }
  }

  // Mobile menu.
  .sf-accordion {
    a.menuparent:after {
      .glyphicon();
      content: @right;
      margin-left: .5em;
      font-size: .625em;
    }
    .sf-expanded > a.menuparent:after {
      content: @down;
    }
    .sf-clone-parent .menuparent:after {
      display: none;
    }
  }

  // Horizontal menu.
  .sf-horizontal {
    // Top-level links, if enabled.
    li.sf-depth-1 > a.menuparent:after when not (@position = false) {
      .glyphicon();
      top: auto;
      & when not (@horizontal_top_down = true) and (@position = 'right') {
        content: @right;
      }
      & when (@horizontal_top_down = true), (@position = 'bottom') {
        content: @down;
      }
      & when (@position = 'right') {
        margin-left: .5em;
        font-size: .625em;
      }
      & when (@position = 'bottom') {
        position: absolute;
        bottom: @bottom;
        left: 50%;
        transform: translateX(-50%);
        font-size: @bottom-font-size;
      }
    }

    // Nested "right" links.
    li.sf-depth-2 > a.menuparent:after {
      .glyphicon();
      content: @right;
      top: auto;
      font-size: .625em;
      margin-left: .5em;
    }
  }
}

/**
 * Adds hierarchical background colors to Superfish accordion links.
 *
 * Example:
 *
 * .some-wrapper {
 *   .superfish-accordion-bg-colors();
 * }
 *
 * @color: the top-level background color (nested levels will be lighter)
 * @url: the background image (if any)
 */
.superfish-accordion-bg-colors(@color: black, @url: ~'') {
  .sf-accordion {
    a {
      background: @color @url;
    }
    ul a {
      background: fade(white, 10%) @url;
    }
    ul ul a {
      background: fade(white, 20%) @url;
    }
    ul ul ul a {
      background: fade(white, 30%) @url;
    }
  }
}
